.jodit-workplace {
  min-height: 550px!important;
  background-color: white;
}

.jodit-status-bar-link {
  display: none;
}

.jodit-toolbar-button_about {
  display: none;
}